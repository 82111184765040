import React from "react";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "../core";

type Props = {
  title: string;
  services: { iconUrl: string; title: string; description: string }[];
};

export default function HomeWhatSection({ title = "Title", services = [] }: Props) {
  return <section className="bg-accent1 p-20 md:p-40">{mainCard()}</section>;

  function mainCard() {
    return (
      <div className={cx("wrapper", "rounded-lg bg-bg1", "px-20 md:px-40 lg:px-60 py-20 md:py-40")}>
        {leadText()}
        <div className="h-20 md:h-30 bg-transparent" />
        {servicesGrid()}
      </div>
    );
  }

  function leadText() {
    return (
      <div className={cx("flex flex-col justify-center items-center")}>
        <div className="w-auto md:w-6/12 lg:w-8/12 px-40">
          <h2 className="font-medium text-md2 lg:text-md text-accent1 text-center">{title}</h2>
        </div>
      </div>
    );
  }

  function servicesGrid() {
    const colors = [
      theme.colorsTw.bg_accent2_shaded,
      theme.colorsTw.bg_accent3_shaded,
      theme.colorsTw.bg_accent1_shaded,
    ];
    const marginOffset = ["lg:mt-0", "lg:mt-0", "lg:mt-0"];
    return (
      <div className={cx("grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-20")}>
        {services.map((item, index) => {
          return serviceCard(
            item.title,
            item.description,
            item.iconUrl,
            colors[index],
            marginOffset[index]
          );
        })}
      </div>
    );
  }

  function serviceCard(
    title: string,
    description: string,
    iconUrl: string,
    backgroundColor: string = "bg-red-100",
    marginOffset: string = "mt-0"
  ) {
    return (
      <div
        key={title}
        className={cx(
          "rounded-lg w-auto lg:min-h-440 lg:max-h-600 p-10 lg:p-20",
          backgroundColor,
          marginOffset
        )}>
        <img className="w-80 md:w-100 h-80 md:h-100" src={iconUrl} alt={title} />

        <div className="h-20 bg-transparent" />

        <div className="px-10 pb-20">
          <h3 className="font-medium text-md3 text-text1">{title}</h3>
          <div className="h-16 bg-transparent" />
          <p className="text-md4 text-text2 leading-relaxed">{description}</p>
        </div>
      </div>
    );
  }
}
