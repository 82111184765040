import React from "react";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "../core";

type Props = {
  title: string;
  description: string;
};

export default function HomeWhySection({ title = "Title", description = "Description" }: Props) {
  return (
    <section className={cx("bg-bg2", theme.spacing.sectionY_lg)}>
      <div className={cx("wrapper", "flex flex-col", "px-20 md:px-80")}>{leadText()}</div>
    </section>
  );

  function leadText() {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="md:w-10/12">
          <h2 className="font-medium text-md2 lg:text-lg4 text-accent1 text-center">{title}</h2>
          <div className="h-10 lg:h-20 bg-transparent" />
          <p className="text-md2 md:text-md lg:text-md text-text1 leading-normal text-center">
            {description}
          </p>
        </div>
      </div>
    );
  }
}
