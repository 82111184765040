import React from "react";
import cx from "classnames";

import theme from "../../constants/theme";

import { LocaleButton } from "../core";
import { IconArrowRight } from "../icons/line";

type SolutionPreview = {
  title: string;
  description: { localized: string };
  coverImage: { asset: { url: string } };
  detailPageCallToAction: {
    linkTitle: { localized: string };
    route: { slug: { current: string } };
  };
};

type Props = {
  title: string;
  description: string;
  solutions: SolutionPreview[];
};

export default function HomeSolutionsSection({
  title = "Title",
  description = "Description",
  solutions = [],
}: Props) {
  return (
    <section id="solutions" className={cx("bg-bg1", theme.spacing.sectionY)}>
      <div className={cx("wrapper", "flex flex-col", "px-20 md:px-80")}>
        {leadText()}
        <div className="h-20 md:h-40 bg-transparent" />
        {solutionsGrid()}
      </div>
    </section>
  );

  function leadText() {
    return (
      <div className="flex flex-col justify-center items-start">
        <div className="md:w-8/12">
          <h2 className="font-medium text-md2 lg:text-lg4 text-accent1">{title}</h2>
          <div className="h-10 lg:h-20 bg-transparent" />
          <p className="text-md2 md:text-md lg:text-lg4 text-text1 leading-normal">{description}</p>
        </div>
      </div>
    );
  }

  function solutionsGrid() {
    return (
      <div className={cx("grid grid-cols-1 gap-10 md:gap-20")}>
        {solutions.map((item, index) => {
          const isFlipped = index % 2 !== 0;
          return solutionCard(
            item.title,
            item.description?.localized,
            item.coverImage?.asset?.url ??
              "https://upload.wikimedia.org/wikipedia/commons/a/a1/Mallard2.jpg",
            item.detailPageCallToAction.linkTitle.localized ?? "Link",
            item.detailPageCallToAction.route.slug.current ?? "/",
            isFlipped
          );
        })}
      </div>
    );
  }

  function solutionCard(
    title: string,
    description: string,
    coverImage: string,
    callToActionTitle: string,
    callToActionRoute: string,
    isFlipped: boolean = false
  ) {
    const orderLast = isFlipped ? "lg:order-last" : null;
    const backgroundColor = isFlipped
      ? theme.colorsTw.bg_accent2_shaded
      : theme.colorsTw.bg_accent1_shaded;
    return (
      <div
        key={title}
        className={cx("rounded-lg w-auto lg:h-600 lg:max-h-600 p-40 lg:p-60", backgroundColor)}>
        <div className="w-full h-full flex flex-col lg:flex-row items-center">
          <div className={cx("w-auto lg:w-6/12 lg:px-40 pb-20", orderLast)}>
            <img className="w-full h-full" src={coverImage} alt={title} />
          </div>

          <div className="w-auto lg:w-6/12 lg:px-40">
            <h3 className="font-medium text-md3 md:text-md2 lg:text-lg4 text-text1">{title}</h3>
            <div className="h-16 bg-transparent" />
            <p className="text-md4 md:text-md3 text-text2 leading-relaxed pb-20 lg:pb-40">
              {description}
            </p>
            <LocaleButton
              to={callToActionRoute}
              label={callToActionTitle}
              icon={<IconArrowRight color={theme.colors.altText1} />}
              size={`lg`}
              labelColor={theme.colors.altText1}
              frameColor={theme.colors.accent3}
              frameColorHover={theme.colors.accent3}
            />
          </div>
        </div>
      </div>
    );
  }
}
