import React from "react";

import cx from "classnames";
import theme from "../../constants/theme";

import { VideoBlock } from "../blocks";
import { Wrapper } from "../core";

type Props = {
  title: string;
  description: string;
  coverImageUrl?: string;
  videoLink?: string;
  showVideo?: boolean;
  backgroundColor?: string;
};

export default function AboutSubHeader({
  title,
  description,
  coverImageUrl,
  videoLink,
  showVideo,
  backgroundColor = theme.colorsTw.bg_bg1,
}: Props) {
  return (
    <header className={cx(theme.spacing.sectionY, backgroundColor)}>
      <Wrapper isFullHeight={true}>
        <div className="flex flex-col md:flex-row items-center">
          {textGroup()}
          {showVideo ? video() : coverImage()}
        </div>
      </Wrapper>
    </header>
  );

  function textGroup() {
    return (
      <div className="flex items-center w-10/12">
        <div className="pb-20">
          <h1 className="font-medium text-lg4 md:text-lg3 lg:text-lg2 text-text1 leading-none">
            {title}
          </h1>
          <div className="h-10 lg:h-20 bg-transparent" />
          <div className="border-l-4 border-solid border-accent3 pl-20 md:pr-80">
            <p className="md:text-md3 text-text2 leading-loose">{description}</p>
          </div>
        </div>
      </div>
    );
  }

  function coverImage() {
    return (
      <div className="flex items-center md:w-4/12 lg:w-6/12 h-full">
        <img className="w-full h-full object-cover" src={coverImageUrl} alt={title} />
      </div>
    );
  }

  function video() {
    if (videoLink === undefined) {
      return null;
    }
    return <VideoBlock videoLink={videoLink} />;
  }
}
