import React from "react";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "../core";

type Props = {
  title: string;
  description: string;
  services: { iconUrl: string; title: string; description: string }[];
  backgroundColor?: string;
};

export default function AboutWhatSection({
  title = "Title",
  description = "Description",
  services = [],
  backgroundColor = theme.colorsTw.bg_bg1,
}: Props) {
  return (
    <section id="services" className={cx(backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {leadText()}
        <div className={cx("bg-transparent", theme.spacing.blockY)} />
        {servicesGrid()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="md:w-10/12 lg:w-8/12 px-40">
          <h2 className="font-medium text-md2 lg:text-md text-accent1 text-center">{title}</h2>
          <div className="h-10 lg:h-20 bg-transparent" />
          <p className="text-md2 md:text-md lg:text-lg4 text-text1 leading-normal text-center">
            {description}
          </p>
        </div>
      </div>
    );
  }

  function servicesGrid() {
    const colors = [
      theme.colorsTw.bg_accent2_shaded,
      theme.colorsTw.bg_accent3_shaded,
      theme.colorsTw.bg_accent1_shaded,
    ];
    return (
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">
          <div>
            {serviceCard(
              services[0].title,
              services[0].description,
              services[0].iconUrl,
              colors[0]
            )}
            {serviceCard(
              services[1].title,
              services[1].description,
              services[1].iconUrl,
              colors[1]
            )}
          </div>
          <div className="flex justify-center items-center h-full">
            {serviceCard(
              services[2].title,
              services[2].description,
              services[2].iconUrl,
              colors[2]
            )}
          </div>
        </div>
      </div>
    );
  }

  function serviceCard(
    title: string = "Title",
    description: string = "Description",
    iconUrl: string,
    backgroundColor: string = "bg-accent1_20"
  ) {
    return (
      <div key={title} className={cx("p-10 lg:h-600")}>
        <div className={cx("rounded-lg p-10 md:p-20 w-full h-full", backgroundColor)}>
          <img className="w-80 md:w-120 h-80 md:h-120" src={iconUrl} alt={title} />

          <div className="h-20 bg-transparent" />

          <div className="px-20 lg:px-40 pb-40">
            <h3 className="font-medium text-md3 lg:text-md2 text-text1">{title}</h3>
            <div className="h-16 bg-transparent" />
            <p className="text-md4 lg:text-md3 text-text2 leading-relaxed">{description}</p>
          </div>
        </div>
      </div>
    );
  }
}
