/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect, useState, useRef } from "react";
import cx from "classnames";
import gsap from "gsap";

import theme from "../../constants/theme";

import { Wrapper, LocaleButton } from "../core";
import {
  geometricBottomLeft,
  geometricBottomRight,
  geometricTopLeft,
  geometricTopRight,
} from "../../assets/graphics";
import { headerIllustrationHome1, headerIllustrationHome2 } from "../../assets/graphics";
import { useLayoutEffect } from "react";

type Props = {
  title: string;
  description: string;
  coverImageUrl?: string;
  callToAction: {
    linkTitle: { localized: string };
    route: { slug: { current: string } };
  };
};

const headerImages = [
  { title: "Milk", image: headerIllustrationHome1 },
  { title: "Beer", image: headerIllustrationHome2 },
];

export default function HomeHeader({
  title = "Title",
  description = "Description",
  coverImageUrl,
  callToAction = { linkTitle: { localized: "Link" }, route: { slug: { current: "/slug" } } },
}: Props) {
  let headerImageIndex = 0;
  const [headerImage, setHeaderImage] = useState(headerImages[headerImageIndex]);
  const headerRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(onTimer, 6000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function onTimer() {
    const newIndex = headerImageIndex < headerImages.length - 1 ? headerImageIndex + 1 : 0;
    headerImageIndex = newIndex;
    setHeaderImage(headerImages[newIndex]);
    gsap.from(headerRef.current, { autoAlpha: 0, duration: 1, ease: "easeInOut" });
  }

  return (
    <header
      className={cx("lg:h-screen-exclNavBar-lg", theme.spacing.sectionY)}
      css={{
        backgroundImage: `
        url(${geometricTopLeft}), 
        url(${geometricBottomLeft}),
        url(${geometricTopRight}),
        url(${geometricBottomRight})
      `,
        backgroundPosition: "top left, bottom left, top right, bottom right",
        backgroundRepeat: "no-repeat",
        backgroundSize: 150,
        [theme.mq(theme.screens.md)]: {
          backgroundSize: 200,
        },
        [theme.mq(theme.screens.lg)]: {
          backgroundSize: 300,
        },
      }}>
      <Wrapper isFullHeight={true}>
        <div className="flex flex-col md:flex-row items-center h-full">
          {textGroup()}
          {imageGroup()}
        </div>
      </Wrapper>
    </header>
  );

  function textGroup() {
    return (
      <div className="flex items-center md:w-10/12 lg:w-7/12 h-full">
        <div className="">
          <h1 className="font-medium text-md md:text-lg4 lg:text-lg2 text-text1 leading-none">
            {title}
          </h1>
          <div className="h-10 lg:h-20 bg-transparent" />
          <p className="text-md4 md:text-md3 lg:text-md2 text-text2 leading-loose lg:w-600">
            {description}
          </p>
          <div className="h-20 md:h-30 bg-transparent" />
          <div className="lg:w-560 md:pb-40 lg:pb-80">
            <LocaleButton
              to={callToAction.route.slug.current}
              label={callToAction.linkTitle.localized}
              size={`xl`}
              labelColor={theme.colors.altText1}
              frameColor={theme.colors.accent3}
              frameColorHover={theme.colors.accent3}
            />
          </div>
        </div>
      </div>
    );
  }

  function imageGroup() {
    return (
      <div className="flex items-center w-auto md:w-8/12 lg:w-6/12 h-full pb-80">
        <img
          ref={headerRef}
          className="w-full h-auto fadein"
          src={headerImage.image}
          alt={headerImage.title}
        />
      </div>
    );
  }
}
