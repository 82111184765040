import React from "react";

type Props = {
  videoLink: string;
};

export default function VideoBlock({ videoLink }: Props) {
  return (
    <div className="relative w-320 md:w-full pb-4/12">
      <iframe
        className="absolute w-full h-full left-0 top-0 max-h-600"
        src={videoLink}
        width="640"
        height="362"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      />
    </div>
  );
}
