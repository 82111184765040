import React from "react";
import cx from "classnames";

import theme from "../../constants/theme";
import { Wrapper } from "../core";

type Props = {
  title: string;
  subTitle?: string;
  description: string;
  coverImageUrl?: string;
  backgroundColor?: string;
};

export default function SolutionDetailHeader({
  title = "Title",
  subTitle,
  description = "Description",
  coverImageUrl,
  backgroundColor = theme.colorsTw.bg_accent2,
}: Props) {
  return (
    <header className={cx(theme.spacing.sectionY, backgroundColor)}>
      <Wrapper isFullHeight={true}>
        <div className="flex flex-col md:flex-row items-center">
          {textGroup()}
          {imageGroup()}
        </div>
      </Wrapper>
    </header>
  );

  function textGroup() {
    const subTitleEl = subTitle ? (
      <p className="font-medium text-md4 text-altText1_60 uppercase tracking-wide_lg pb-8">
        {subTitle}
      </p>
    ) : null;
    return (
      <div className="flex items-center w-10/12 md:w-8/12 lg:w-7/12 h-full">
        <div className="pb-20">
          {subTitleEl}
          <h1 className="font-medium text-lg4 md:text-lg3 lg:text-lg2 text-altText1 leading-none">
            {title}
          </h1>
          <div className="h-10 lg:h-20 bg-transparent" />
          <div className="border-l-4 border-solid border-accent3 pl-20 md:pr-80">
            <p className="md:text-md3 text-altText1 leading-loose">{description}</p>
          </div>
        </div>
      </div>
    );
  }

  function imageGroup() {
    return (
      <div className="flex items-center md:w-4/12 lg:w-6/12 h-full">
        <img className="w-full h-full object-cover" src={coverImageUrl} alt={title} />
      </div>
    );
  }
}
