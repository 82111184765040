/** @jsx jsx */
import { jsx } from "@emotion/react";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, LocaleButton } from "../core";
import {
  geometricBottomLeft,
  geometricBottomRight,
  geometricTopLeft,
  geometricTopRight,
} from "../../assets/graphics";

type Props = {
  title: string;
  callToAction: {
    linkTitle: { localized: string };
    route: { slug: { current: string } };
  };
  backgroundColor: string;
};

export default function CTASection({
  title = "Title",
  callToAction = {
    linkTitle: { localized: "Link" },
    route: { slug: { current: "/slug" } },
  },
  backgroundColor = theme.colorsTw.bg_accent1,
}: Props) {
  return <section className={cx(backgroundColor, theme.spacing.sectionY_md)}>{card()}</section>;

  function card() {
    return (
      <Wrapper>
        <div
          className={cx(
            "flex flex-col justify-center items-center",
            "px-30 py-40 md:py-60 lg:py-80",
            "h-400",
            "rounded-lg bg-bg2"
          )}
          css={{
            backgroundImage: `
              url(${geometricTopLeft}), 
              url(${geometricBottomLeft}),
              url(${geometricTopRight}),
              url(${geometricBottomRight})
            `,
            backgroundPosition: "top left, bottom left, top right, bottom right",
            backgroundRepeat: "no-repeat",
            backgroundSize: 150,
            [theme.mq(theme.screens.md)]: {
              backgroundSize: 150,
            },
            [theme.mq(theme.screens.lg)]: {
              backgroundSize: 200,
            },
          }}>
          <div className="w-w-10/12 md:w-6/12 lg:w-4/12">
            <h2 className="font-medium text-lg4 text-text1 text-center leading-none">{title}</h2>
          </div>

          <div className="h-30 bg-transparent" />

          <div className="w-full md:w-300">
            <LocaleButton
              href={callToAction.route.slug.current}
              mode={`href`}
              label={callToAction.linkTitle.localized}
              labelColor={theme.colors.altText1}
              frameColor={theme.colors.accent3}
              frameColorHover={theme.colors.accent3}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}
