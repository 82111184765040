import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";
import theme from "../../constants/theme";

import { RichTextContent } from "../core";

const ContentPage = props => {
  return (
    <section className="bg-bg2 py-10 md:py-20">
      <div className="wrapper px-10 md:px-30">
        <article className="w-auto md:w-11/12 lg:w-12/12 m-auto px-20 md:px-60 lg:px-100 py-30 md:py-60 lg:py-80 bg-bg1 rounded-lg shadow-sm border-solid border-one border-gray-200">
          {props.mode === "blog" ? leadText() : leadTextBasic()}
          {props.coverImageUrl ? coverImage() : null}
          <RichTextContent blocks={props.blocks ?? []} />
        </article>
      </div>
    </section>
  );

  function leadText() {
    return (
      <div className="w-full pb-20 md:pb-32">
        <p className="font-medium text-sm2 md:text-sm text-accent3 uppercase tracking-wide_lg">
          {props.category}
        </p>
        <div className="h-8 bg-transparent" />
        <h1 className="font-medium text-md md:text-lg4 lg:text-lg3 text-text1 leading-none">
          {props.title}
        </h1>
        <div className="h-10 bg-transparent" />

        <div className="flex">
          <p className="text-md4 text-accent1 leading-loose">
            <span className="text-text3">By</span> {props.author}
          </p>
          <div className="w-20 bg-transparent" />
          <p className="text-md4 text-text3 leading-loose">{props.date}</p>
        </div>
      </div>
    );
  }

  function leadTextBasic() {
    return (
      <div className="w-full pb-20 md:pb-32">
        <h1 className="font-medium text-md md:text-lg4 lg:text-lg3 text-text1 leading-none">
          {props.title}
        </h1>
      </div>
    );
  }

  function coverImage() {
    return (
      <div className="w-full pt-10 md:pt-20 mb-20 md:pb-30">
        <img
          className="w-full h-auto max-h-600 object-contain object-center"
          src={props.coverImageUrl}
          alt={props.title}
        />
      </div>
    );
  }
};

ContentPage.propTypes = {
  mode: PropTypes.oneOf(["blog", "basic"]),
  title: PropTypes.string,
  blocks: PropTypes.array,
  coverImageUrl: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  category: PropTypes.string,
};
ContentPage.defaultProps = {
  mode: "blog",
  blocks: [],
};

export default ContentPage;
