import React from "react";
import cx from "classnames";

import theme from "../../constants/theme";
import { VideoBlock } from "../blocks";
import { Wrapper, CoverImage, RichDescription } from "../core";

type Props = {
  title: string;
  subTitle?: string;
  descriptionRichText?: any;
  coverImageUrl?: string;
  videoLink?: string;
  showVideo?: boolean;
  sectionColor?: string;
  isFlipped?: boolean;
};

export default function StandOutSection({
  title,
  subTitle,
  descriptionRichText,
  coverImageUrl,
  videoLink,
  showVideo = false,
  sectionColor = theme.colorsTw.bg_accent2,
  isFlipped = false,
}: Props) {
  return (
    <section className={cx(sectionColor, theme.spacing.sectionY)}>
      <Wrapper>{feature()}</Wrapper>
    </section>
  );

  function feature() {
    return (
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className={cx(isFlipped ? "block" : "hidden", "lg:w-1/12")}></div>

        {coverImageUrl && (
          <div className={cx(isFlipped ? "md:order-last" : "order-first", "w-auto md:w-6/12")}>
            {showVideo ? video() : coverImage()}
          </div>
        )}

        <div className="h-40 md:hidden bg-transparent" />

        <div className={cx("w-auto", !coverImageUrl ? "md:w-10/12" : "md:w-6/12")}>
          <div className="px-40">
            {subTitle && (
              <p className="text-sm text-accent2 uppercase tracking-wide_lg">{subTitle}</p>
            )}
            <div className="h-4 bg-transparent" />
            {title && (
              <h2 className="font-medium text-md lg:text-lg4 text-text1 leading-none">{title}</h2>
            )}
            <div className="h-20 lg:h-30 bg-transparent" />
            {description()}
          </div>
        </div>
      </div>
    );
  }

  function description() {
    if (descriptionRichText) {
      return <RichDescription blocksObject={descriptionRichText} />;
    }
  }

  function coverImage() {
    return <CoverImage imageUrl={coverImageUrl} alt={title} />;
  }

  function video() {
    if (videoLink === undefined) {
      return null;
    }
    return <VideoBlock videoLink={videoLink} />;
  }
}
